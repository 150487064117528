<script setup>
import Section from '@/Components/Section.vue';
import Loader from '@/Components/Loader.vue';

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps( {
    profile: Object,
    interval: Number,
} )

// Set slides list
const slides = ref( [] );

// Load slides when mounting and subscribe to websocket
onMounted( () => {
    loadSlides();

    // Interval for every 5 minutes
    setInterval(() => {
        loadSlides();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.slides', (data) => {
        if (data?.hasChanged) {
            loadSlides();
        }
    } );
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.slides' );
} )

/**
 * Load slides from API
 */
const loadSlides = () => {
    axios.get( `/api/${props.profile.slug}/slides` ).then( (response) => {
        const { data } = response;

        slides.value = data.slides;
    } );
}

</script>

<template>
    <Section :padding="false">
        <div class="flex grow" v-if="slides.length > 0">
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :pagination="{ clickable: true }"
                :grabCursor="true"
                :virtual="true"
                :loop="true"
                :modules="[Pagination, Virtual]">
                <swiper-slide
                    v-for="slide in slides"
                    :key="slide.title"
                    :virtualIndex="slide.title	">
                    <div class="flex flex-col items-center justify-center h-full px-10 text-center text-white bg-cover bg-center" :style="`background-image: url(${slide.image});`">
                        <div class="z-10">
                            <h2 class="text-3xl font-bold mb-3">{{ slide.title }}</h2>
                            <p class="text-base font-medium">{{ slide.text }}</p>
                            <span v-if="slide.author">{{ slide.author }}</span>
                        </div>

                        <div v-if="slide.overlay" class="absolute inset-0 bg-black opacity-10 z-0"></div>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
        <Loader v-else/>
    </Section>
</template>
